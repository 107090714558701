import React, { memo } from 'react';
import checkExtraProps from '@jam3/react-check-extra-props';

import './PaperSVG.scss';

function PaperSVG() {
  return (
    <svg className="PaperSVG" viewBox="0 0 629 549">
      <polygon
        className="paper-light-purple"
        points="124.194524 0.24294752 628.913587 0.24294752 628.913587 657.83262 124.194524 657.83262"
      />
      <polygon
        className="paper-purple"
        points="124.194524 0.24294752 628.913587 0.24294752 628.913587 657.83262 124.194524 657.83262"
      />
      <g className="paper-white-container">
        <polygon
          className="paper-white"
          points="124.194524 0.24294752 628.913587 0.24294752 628.913587 657.83262 124.194524 657.83262"
        />
        <g className="rect-container" transform="translate(183.939443, 126.931564)">
          <rect width="212.818483" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 197.813613)">
          <rect width="297.035423" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 230.716167)">
          <rect width="374.423963" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 262.618505)">
          <rect width="158.191279" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 339.923503)">
          <rect width="134.291877" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 371.825841)">
          <rect width="294.75929" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 464.637593)">
          <rect width="294.75929" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 400.832917)">
          <rect width="158.191279" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(183.939443, 432.735255)">
          <rect width="238.994019" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(368.875291, 400.832917)">
          <rect width="189.488115" height="11.3806676" />
        </g>
        <g className="rect-container" transform="translate(368.875291, 262.618505)">
          <rect width="158.191279" height="11.3806676" />
        </g>
        <g className="logo">
          <path d="M216.87,54.32a.07.07,0,0,0-.07.07h0v6.5a.21.21,0,0,1-.21.19.16.16,0,0,1-.13-.06,5.49,5.49,0,0,0-3.8-1.45c-3.86,0-6.46,2.92-6.46,7.26s2.54,7.27,6.46,7.27a6,6,0,0,0,3.72-1.43.28.28,0,0,1,.21-.1.22.22,0,0,1,.21.15l.28,1c0,.07,0,.09.13.09h2.37a.07.07,0,0,0,.07-.07h0V54.39a.07.07,0,0,0-.07-.07h-2.71Zm-3.79,17.21c-2.47,0-4-1.8-4-4.7s1.53-4.67,4-4.67,3.72,1.7,3.72,4.67S215.37,71.53,213.08,71.53Z" />
          <rect x="222.99" y="59.56" width="2.9" height="14.26" rx="0.07" />
          <rect x="223.03" y="54.8" width="2.85" height="2.9" />
          <path d="M234.54,59.57c-4.09,0-6.73,2.85-6.73,7.26s2.7,7.27,6.73,7.27c3.22,0,5.62-1.64,6.27-4.27h-2.94a.11.11,0,0,0-.13.09,3.26,3.26,0,0,1-3.19,1.67,3.5,3.5,0,0,1-3.71-3.29.76.76,0,0,1,0-.15.21.21,0,0,1,.21-.21H241.1l.07-.09a4.79,4.79,0,0,0,.11-1C241.28,62.42,238.63,59.57,234.54,59.57Zm3.63,6h-7.34a3.49,3.49,0,0,1,3.54-3.44h.17a3.34,3.34,0,0,1,3.62,3,3,3,0,0,1,0,.42Z" />
          <path d="M258.12,59.57a5.45,5.45,0,0,0-3.91,1.65.27.27,0,0,1-.38,0,4.52,4.52,0,0,0-3.77-1.65,4.73,4.73,0,0,0-3.29,1.48.32.32,0,0,1-.22.1.22.22,0,0,1-.21-.15l-.28-1c0-.1-.06-.11-.13-.11h-2.37s-.06,0-.07,0V73.75a.07.07,0,0,0,.07.07h2.71a.07.07,0,0,0,.07-.07h0v-7.7c0-2.51,1-3.89,2.89-3.89s2.88,1.31,2.88,3.89v7.7a.07.07,0,0,0,.07.07h2.73a.07.07,0,0,0,.07-.07v-7.7c0-1.67.3-3.89,2.86-3.89,1.93,0,2.91,1.31,2.91,3.89v7.7a.07.07,0,0,0,.07.07h2.71s.06,0,.07,0V66C263.6,61.78,261.71,59.57,258.12,59.57Z" />
          <path d="M202,71.77a10.82,10.82,0,0,0,1.42-2L201,68.58a7.31,7.31,0,0,1-1.25,1.58,3.82,3.82,0,0,1-2.1,1.07,5.19,5.19,0,0,1-3.8-1.17,8,8,0,0,0-5.59-1.74,6.46,6.46,0,0,0-4,2.27,12,12,0,0,0-1.41,2l2.39,1.19a7.36,7.36,0,0,1,1.26-1.57,3.74,3.74,0,0,1,2.1-1.06q.3,0,.6,0a5.71,5.71,0,0,1,3.19,1.19A8,8,0,0,0,198,74,6.34,6.34,0,0,0,202,71.77Zm0-7.13a10.82,10.82,0,0,0,1.42-2L201,61.45A6.83,6.83,0,0,1,199.72,63a3.87,3.87,0,0,1-2.1,1.08,5.29,5.29,0,0,1-3.8-1.17,8,8,0,0,0-5.59-1.74,6.46,6.46,0,0,0-4,2.27,12,12,0,0,0-1.41,2l2.39,1.19a7.36,7.36,0,0,1,1.26-1.57,3.76,3.76,0,0,1,2.1-1.07h.6a5.79,5.79,0,0,1,3.19,1.2,8,8,0,0,0,5.6,1.7A6.34,6.34,0,0,0,202,64.64Zm0-7.13a11.31,11.31,0,0,0,1.42-2L201,54.32a7,7,0,0,1-1.25,1.57,3.82,3.82,0,0,1-2.1,1.07,5.28,5.28,0,0,1-3.8-1.16,8,8,0,0,0-5.59-1.74,6.37,6.37,0,0,0-4,2.27,11.63,11.63,0,0,0-1.41,2l2.39,1.2A7.68,7.68,0,0,1,186.5,58a3.74,3.74,0,0,1,2.1-1.06h.6a5.79,5.79,0,0,1,3.19,1.2,8,8,0,0,0,5.6,1.69A6.39,6.39,0,0,0,202,57.51Z" />
        </g>
      </g>
    </svg>
  );
}

PaperSVG.propTypes = checkExtraProps({});

PaperSVG.defaultProps = {};

export default memo(PaperSVG);
