import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './SignCTA.scss';

import { graphql } from 'gatsby';
import { customPropTypes, sanitizer, useCSSMotion, useCTAClickTracking, useSectionTimeSpent } from '../../util';

import { CTAInternalLink } from '../CTA';
import PaperSVG from '../PaperSVG/PaperSVG';

function SignCTA({ data: { title, description, cta, id, name } }) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  const handleCtaClick = useCTAClickTracking(name);

  useSectionTimeSpent(name, containerRef);
  return (
    <section id={id} className={classnames('SignCTA', motionState)} ref={containerRef}>
      <div className="container">
        <div className="content">
          <h2 className="title">{title}</h2>
          <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
          <CTAInternalLink text={cta?.text} link={cta?.link} motionState={motionState} onClick={handleCtaClick} />
        </div>
        <div className="svg-container">
          <PaperSVG />
        </div>
      </div>
    </section>
  );
}

SignCTA.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    title: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
  }).isRequired,
});

SignCTA.defaultProps = {};

export default memo(SignCTA);

export const fragments = graphql`
  fragment ClaSignCta on WpPage_Flexiblelayout_FlexibleChildren_ClaSignCta {
    cta {
      link
      text
    }
    id
    jumpToLinkTitle
    name
    title
  }

  fragment ClaSignCta_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ClaSignCta {
    cta {
      link
      text
    }
    id
    jumpToLinkTitle
    name
    title
  }
`;
